import React, { useEffect, useState } from "react";
import css from "../css/Refer.module.css";
import axios from "axios";
import Swal from "sweetalert2";

const Refer = () => {
  const [user, setUser] = useState();
  const Cashheader = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(`https://apifronttg.rushkingludo.com/me`, { headers })
      .then((res) => {
        setUser(res.data);
        Allgames(res.data.referral_code);
      })
      .catch((e) => {
        alert(e.msg);
      });
  };

  const [cardData, setGame] = useState([]);
  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(`https://apifronttg.rushkingludo.com/referral/code/${id}`, {
        headers,
      })
      .then((res) => {
        setGame(res.data);
      });
  };

  useEffect(() => {
    Cashheader();
  }, []);

  const copyCode = (e) => {
    const referralLink = `https://rushkingludo.com/login/${user.referral_code}`;
    navigator.clipboard.writeText(referralLink);
    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  if (user === undefined) {
    return null;
  }

  return (
    <div>
      <div className="leftContainer">
        <div className={`${css.center_xy} pt-5 mx-3`}>
          <picture className="mt-4">
            <img
              alt="img"
              src={process.env.PUBLIC_URL + "Images/refer/refer1.png"}
              className="img-fluid"
              width={"350px"}
            />
          </picture>
          <div className="refer_box mb-1 mt-3">
            <div className="font-15 text-center earn">
              Earn now unlimited
              <span aria-label="party-face">🥳</span>
            </div>
            <div className="d-flex justify-content-center font-weight-bold ">
              Refer your friends now!
            </div>

            <div className="d-flex justify-content-around my-2">
              <div className="border-right w-50 text-center">
                <div>Total Refers:</div>
                <b>{cardData && cardData}</b>
              </div>
              <div className="text-center font-9 w-50">
                <div>Total Earned:</div>
                <b>{user.referral_earning} ₹</b>
              </div>
            </div>
          </div>

          <div className={`${css.text_bold} mt-3 text-center refer_box`}>
            <div className="font-15 text-center mb-1 earn">Referral Code</div>

            <div className="d-flex p-3 align-items-stretch">
              <h4 className="border-1 border w-100 text-left px-2 m-0 refer_code">
                {user.referral_code}
              </h4>
              <button
                className="cash_btn py-1 w-100"
                onClick={(e) => copyCode(e)}
              >
                Copy Code
              </button>
            </div>
          </div>

          <div className="refer_box mt-3">
            <div
              className={`${css.font_11} ${css.text_bold} p-2 text-center earn`}
            >
              Refer &amp; Earn Rules
            </div>
            <div className="d-flex align-items-center m-3">
              <picture>
                <img
                  alt="img"
                  width="82px"
                  src={process.env.PUBLIC_URL + "Images/refer/giftbanner.png"}
                  className="snip-img"
                />
              </picture>
              <div className={`${css.font_9} px-2 text-light`}>
                <span>
                  When your friend signs up on Rush King ludo from your referral
                  link,
                </span>
                <div className={`${css.font_8} ${css.c_green} mt-2 text-light`}>
                  You get
                  <strong>&nbsp; 2% Commission</strong>
                  &nbsp;on your
                  <strong>&nbsp; referral's winnings.</strong>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center p-3">
              <picture>
                <img
                  alt="img"
                  width="82px"
                  src={process.env.PUBLIC_URL + "Images/refer/banner.png"}
                  className="snip-img bg-light"
                />
              </picture>
              <div className={`${css.font_9} px-2 text-light`}>
                <div>
                  Suppose your referral plays a battle for a 10000 Cash,
                </div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get
                  <strong>200 Cash</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${css.refer_footer}`}>
          <div className="back_btn">
            <a href="#" className="btn">
            <i className="fa fa-share mr-2 d-inline"></i>Share
            </a>
          </div>
          <a className="w-100"
            href={`whatsapp://send?text=Play Ludo and earn Rs10000 daily.Commission Charge - 5% OnlyReferral - 3% On All Games.24x7 whats app Chat Support Instant Withdrawal Via UPI/Bank Register Now, My refer code is    👇  ${user.referral_code}.
     👇
     https://rushkingludo.com/login/${user.referral_code}`}
          >
            <div className="bg-green text-white referbtn_refer cxy p-2">
              <i className="fa fa-whatsapp mr-2"></i> Share on Whatsapp
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Refer;
