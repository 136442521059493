import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}

const Addcase2 = (props) => {
  const [caseId, setCaseId] = useState(null);
  const [isbn, setIsbn] = useState(null);

  useEffect(() => {
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split("/");

    if (pathSegments.length >= 5) {
      setCaseId(pathSegments[2]);
      setIsbn(pathSegments[5]);
    }
  }, []);

  const handleButtonClick = () => {
    const amount = caseId;
    const mobile = isbn;
    const redirectUrl = `/Manual_Payment?&amount=${amount}&mobile=${mobile}`;
    window.location.href = redirectUrl;
  };

  return (
    <div className="leftContainer" style={{ height: "100vh" }}>
      <div className="px-4 mt-5 pt-4 text-center">
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: "#fafafa",
            border: "1px solid #e0e0e0",
            borderRadius: "7px",
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{
              height: "60px",
              display: "flex",
              textAlign: "center",
            }}
          >
            <img
              width="40px"
              src="/UPI.png"
              alt=""
              style={{
                marginLeft: "7px",
                paddingBottom: "10px",
                paddingLeft: "3px",
                paddingTop: "5px",
              }}
            />
          </div>

          <div className="d-flex justify-content-center flex-column ml-4">
            <div id="renderSabPaisa" className="jss30"></div>
            <div className="jss30">
              <button
                style={{
                  borderRadius: "8px",
                  border: "none",
                  backgroundColor: "none",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                onClick={handleButtonClick}
              >
                Proceed To Pay
              </button>
            </div>
            <div className="jss31"></div>
          </div>
        </div>

        <div style={{ margin: "20px 0" }}>
          <span style={{ fontSize: "14px" }} className="text-warning">
            जब आप पेमेंट ऐड कर रहे हो तो वहाँ जब तक ऑटोमैटिक पेमेंट
            <br />
            ऐड नहीं हो जाये तब तक ना बैक आये और ना ही refresh करे ।
          </span>
        </div>
        <div style={{ margin: "20px 20px" }}>
          <span style={{ color: "red", fontSize: "14px" }}></span>
        </div>
      </div>
    </div>
  );
};

export default Addcase2;
