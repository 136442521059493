import React, { useEffect, useState } from "react";
import "../css/kyc.css";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/Pan.module.css";
import "../css/Loader.css";
import axios from "axios";
import Swal from "sweetalert2";

const Manual_Payment = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;
  
  const [amount, setAmount] = useState(0);
  const [utrNumber, setUtrNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Selectoption");
  const [upiId, setUpiId] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [upiSettings, setUpiSettings] = useState({ isEnabled: false, upiId: "" });
  const [accountSettings, setAccountSettings] = useState([]);
  const [websiteSettings, setWebsiteSettings] = useState({ isAccountOn: true, isUpiOn: true });
  const [selectedUpiSetting, setSelectedUpiSetting] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    var amountParam = queryParams.get('amount');
    const utrParam = queryParams.get('utr');
    if (amountParam) {
      setAmount(amountParam);
    }
    if (utrParam) {
      setUtrNumber(utrParam);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + "settings/data");
        setWebsiteSettings(response.data);
      } catch (error) {
        console.error("Error fetching website settings: ", error);
      }
    };
    fetchData();
  }, [baseUrl]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    var amountParam = queryParams.get('amount');
   
    if (websiteSettings.isUpiOn) {
      const fetchUpiSettings = async () => {
        try {
          const response = await axios.get(`${baseUrl}User/upisettings`);
          if (response.data.status === 'success') {
            const enabledSetting = response.data.upiSettings.filter(setting => setting.isEnabled);
            
            if (enabledSetting.length > 0) {
              const selectedSettings = [];
  
              for (const setting of enabledSetting) {
                if (amountParam >= setting.minAmount && amountParam <= setting.maxAmount) {
                  selectedSettings.push(setting);
                }
              }
  
              if (selectedSettings.length > 0) {
                setUpiSettings(selectedSettings);
                setSelectedUpiSetting(selectedSettings[0]);
                
              } else {
                console.warn("No UPI settings match the amount range");
              }
            } else {
              console.warn("No enabled UPI settings found");
            }
          } else {
            console.error("Failed to fetch UPI settings");
          }
        } catch (error) {
          console.error("Error fetching UPI settings:", error);
        }
      };
  
      fetchUpiSettings();
    }
  }, [baseUrl, websiteSettings.isUpiOn]);

  useEffect(() => {
    if (websiteSettings.isAccountOn) {
      const fetchAccountSettings = async () => {
        try {
          const response = await axios.get(`${baseUrl}User/accountSettings`);
          if (response.data.status === 'success') {
            const enabledAccounts = response.data.accountSettings.filter(setting => setting.isEnabled);
            setAccountSettings(enabledAccounts);
            if (enabledAccounts.length > 0) {
              setAccountNumber(enabledAccounts[0].accountNumber);
              setBeneficiaryName(enabledAccounts[0].beneficiaryName);
              setIfscCode(enabledAccounts[0].ifscCode);
            }
          } else {
            console.error("Failed to fetch account settings");
          }
        } catch (error) {
          console.error("Error fetching account settings:", error);
        }
      };
      fetchAccountSettings();
    }
  }, [baseUrl, websiteSettings.isAccountOn]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert("Text copied to clipboard!");
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  const handleConfirm = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    };

    if (!/^\d{12}$/.test(utrNumber)) {
      Swal.fire({
        title: "Invalid UTR Number",
        text: "Please enter a valid 12-digit UTR Number",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}user/manualpayment`,
        {
          paymentMethod,
          upiId: paymentMethod === 'UPI' ? selectedUpiSetting.upiId : undefined,
          accountNumber: paymentMethod === 'Account' ? accountNumber : undefined,
          ifscCode: paymentMethod === 'Account' ? ifscCode : undefined,
          beneficiaryName: paymentMethod === 'Account' ? beneficiaryName : undefined,
          amount,
          urtID: utrNumber
        },
        { headers: headers }
      );

      const data = response.data;
      if (data.status === "ok") {
        Swal.fire({
          title: "Payment Successfully Added",
          icon: "success",
          confirmButtonText: "OK",
        });
        window.location.reload();
      } else {
        Swal.fire({
          title: data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error confirming payment: ", error);
      alert("Error confirming payment");
    }
  };

  return (
    <div>
      <div className="leftContainer">
        <div className="manual_pay px-4 mt-5 pt-4 text-center">
          <h3>Payment Amount</h3>
          <h5 className="text-danger">₹ {amount}</h5>
          <div className="form-group">
            <label htmlFor="paymentMethod">Payment Method</label>
            <select 
              id="paymentMethod"
              className="form-control"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              disabled={!websiteSettings.isUpiOn && !websiteSettings.isAccountOn}
            >
              <option value="Selectoption">Select Payment Option</option>
              {websiteSettings.isUpiOn && <option value="UPI">UPI</option>}
              {websiteSettings.isAccountOn && <option value="Account">Account</option>}
            </select>
          </div>
          {paymentMethod === 'UPI' && websiteSettings.isUpiOn ? (
            <>
            {upiSettings.length > 0 ? (
              upiSettings.map((setting, index) => (
                <div key={index} className="d-flex justify-content-between align-items-center border border-1 p-2 rounded mb-2">
                  <div>
                    <p className="mb-0">Pay to UPI:</p>
                    <p className="mb-0 text-danger">{setting.upiId || "UPI ID not found"}</p> 
                  </div>
                  <div className="btn btn-primary text-light py-2 px-3" onClick={() => handleCopy(setting.upiId)}>Copy</div>
                </div>
              ))
            ) : (
              <div className="text-danger mt-3">
                <p>No UPI settings match the amount range.</p>
              </div>
            )}
          </>
          ) : paymentMethod === 'Account' && websiteSettings.isAccountOn ? (
            <div className="form-group mt-3">
              {accountSettings.length > 0 ? (
                <div>
                  <div className="d-flex justify-content-between align-items-center border border-1 p-2 mb-3 rounded">
                   <p className="mb-0">Account Number:</p>
                   <p className="mb-0 text-danger">{accountNumber}</p>
                    <div className="btn btn-primary text-light py-2 px-3" onClick={() => handleCopy(accountNumber)}>Copy</div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center border border-1 p-2 mb-3 rounded">
                   <p className="mb-0">Beneficiary Name:</p>
                   <p className="mb-0 text-danger">{beneficiaryName}</p>
                    <div className="btn btn-primary text-light py-2 px-3" onClick={() => handleCopy(beneficiaryName)}>Copy</div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center border border-1 p-2 rounded">
                   <p className="mb-0">IFSC Code:</p>
                   <p className="mb-0 text-danger">{ifscCode}</p>
                    <div className="btn btn-primary text-light py-2 px-3" onClick={() => handleCopy(ifscCode)}>Copy</div>
                  </div>
                  
                </div>
              ) : (
                <div className="text-danger mt-3">
                  <p>No enabled bank accounts available.</p>
                </div>
              )}
            </div>
          ) : (
            <div className="text-danger mt-3">
              <p>No payment methods are currently enabled.</p>
            </div>
          )}
          <div className="text-left mt-3">
            <span className="text-left font-weight-bold">STEP 1:</span>
            <p className="mb-0">Click on the above button to complete payment of ₹{amount} from your UPI ID or bank account.</p>
            <div className="mt-3">
              <span className="text-left font-weight-bold">STEP 2:</span>
              <p className="mb-0">After payment, enter the UTR number below and click confirm.</p>
            </div>
            <div className="mt-3">
              <label htmlFor="utrNumber" className="mt-2">Enter UTR Number</label>
              <input
                type="text"
                id="utrNumber"
                className="form-control"
                value={utrNumber}
                onChange={(e) => setUtrNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="conf_btn d-flex justify-content-center mt-4">
            <div className="btn btn-primary text-light py-2 px-3" onClick={handleConfirm}>Confirm</div>
          </div>
        </div>
      </div>
      <Rightcontainer />
    </div>
  );
};

export default Manual_Payment;
