import React from "react";
import { useState, useEffect } from "react";
import Logo from "../../assets/images/logo.png";

const Rightcontainer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };
  document.title = WebSitesettings ? WebSitesettings.WebTitle : "win real cash";
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="rightContainer">
        <div className="rcBanner flex-center text-center">
          <picture className="rcBanner-img-container animate__bounce infinite mb-2">
            <img src={Logo} alt="logo" />
          </picture>
          <div
            className="rcBanner-text"
            style={{ fontWeight: "bolder", marginTop: "100px", bottom: "25%" }}
          >
            Rush King Ludo
            <span
              className="rcBanner-text-bold"
              style={{ fontWeight: "normal" }}
            >
              {" "}
              Win Real Cash
            </span>
            <br />
            <a className="cxy" href="">
              {" "}
              <span style={{ color: "#7cfff0" }}> Download Now </span>
            </a>
          </div>
          {/* <div className="rcBanner-footer">
            For best experience, open&nbsp;
            <a href="/" className="right_app">
              <span>Rush King Ludo</span>
            </a>
            &nbsp;on&nbsp;
            <img src={process.env.PUBLIC_URL + "/Images/chrome.png"} alt="" />
            &nbsp;chrome mobile
          </div> */}

          <div className="right_bar">
            <img src="/Images/Homepage/right_rule.png" alt="righticon" />
          </div>

          <div className="support_samll_card_btn_type whatsap_btn">
            <a
              href="https://wa.me/+918741840426?text=Hello"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                width="50px"
                src="/Images/social/whatsapp.png"
                alt="WhatsApp"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rightcontainer;
