import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/terms.css";
import axios from "axios";

const RefundPolicy = () => {
  const [data, setData] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    var baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };
  const appName = "Rush King Ludo";
  const getdata = () => {
    axios.get(baseUrl + `api/term/condition/Refund_Policy`).then((res) => {
      setData(res.data[0].Desc);
    });
  };
  useEffect(() => {
    getdata();
    fetchData();
  }, []);

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="privacy mt-5 py-4 px-3">
          <div className="m-3">
            <h1>Refund Policy</h1>
            <h5>Refund Policy</h5>
            <p className="p2">
              Thanks for being a patron with {appName} Private Limited.
              (referred as “{appName}”) . If you are not entirely satisfied with
              your subscription, we are here to help.
            </p>
            <h5 className="p1">Refund</h5>
            <p className="p2">
              Once we receive your Refund request, we will inspect it and notify
              you on the status of your refund.
            </p>
            <p className="p2">
              If your refund request is approved, we will initiate a refund to
              your credit card (or original method of payment) within 7 working
              days. You will receive the credit within a certain amount of days,
              depending on your card issuer's policies.
            </p>
            <p className="p2">
              In case of unforeseen technical glitch, {appName} Private
              Limited.would refund subscription upon reviewing the complaint.
              Final decision lies with the company.
            </p>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default RefundPolicy;
