import React, { useEffect, useState } from "react";
import "./Component-css/Downloadbutton.css?v=0.1";
import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";

const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <div className={`${css.box} rounded text-center`}>
      <button
        onClick={onClick}
        className={`btn btn-primary btn-sm bg-transparent border-0 ${css.box} rounded p-0`}
        style={{ fontWeight: 500, backgroundColor: "#3A427D" }}
      >
        <div className="bell">
          <picture className={`${css.moneyIcon_container}`}>
            <i
              style={{
                color: "#fff",
                paddingRight: "5px",
                fontSize: "20px",
              }}
              className="fa-solid fa-download mr-0"
            />
          </picture>
        </div>
      </button>
    </div>
  );
};

export default Downloadbutton;
