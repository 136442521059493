import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div>
        <div className="footer_menu">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/">
              <i class="fa-solid fa-house"></i>
              <span>Home</span>
            </Link>
            <Link to="/addcase">
              <img
                src="/Images/Homepage/cash_add.png"
                alt="add cash"
                width={"27px"}
              />
              <span>Add Cash</span>
            </Link>
            <Link to="/wallet">
              <i class="fa-solid fa-wallet"></i>
              <span>Wallet</span>
            </Link>
            <Link to="/profile">
              <i class="fa-solid fa-user"></i>
              <span>Profile</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
